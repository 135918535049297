<template>
<div class="lottery-draw-sidebar">
  <section class="next-draw-wrapper">
    <NextDrawInfo />
  </section>
  <section class="sidebar-statistics-wrapper">
    <Statistics in-draw
                is-drawn-type/>
  </section>
</div>
</template>

<script>
import Statistics from '@/components/Retail/Statistics';
import NextDrawInfo from '@/components/Retail/NextDrawInfo';

export default {
  name: 'LotteryDrawSidebar',
  components: { NextDrawInfo, Statistics },
};
</script>

<style lang="scss" scoped>
.lottery-draw-sidebar {
  display: grid;
  grid-template-rows: 239px calc(100% - 255px);
  grid-row-gap: 16px;
  height: 100%;
  @media (min-width: 1920px) {
    grid-template-rows: 385px calc(100% - 401px);
  }
  .next-draw-wrapper,
  .sidebar-statistics-wrapper {
    background: rgba(45, 45, 45, 0.4);
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(27.1828px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 8px;
    padding: 16px;
  }
  .sidebar-statistics-wrapper {
    .statistics-wrapper {
      justify-content: space-between;
    }
  }
}
</style>
