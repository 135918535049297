<template>
  <article class="next-draw-info">
    <SidebarTitle :title="translate('nextDraw')"
                  :subtitle="`${translate('startingIn')}:`"
                  have-time />
    <div class="next-draw">
      <FlagCategoryTournament class="draw"
                              is-row-view
                              :event="retailNextEvent"
                              :flag-size="getFlagSize"/>
      <div class="next-draw-info-market">
        <div class="market-chunk"
             v-for="(outcomes, index) in getMarkets"
             :key="index">
          <div class="market-outcome"
               v-for="(outcome, key) in outcomes"
               :key="`outcome-${key}`">
            <div class="outcome-value">
              {{outcome.shortName}}
            </div>
            <div class="outcome-odd">
              {{getOdd(outcome.odd)}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import SidebarTitle from '@/components/Retail/SidebarTitle';
import { mapGetters } from 'vuex';
import FlagCategoryTournament from '@/components/Retail/FlagCategoryTournament';
import { chunk } from 'lodash';
import { searchMarket, getOdd } from '@/utility';

export default {
  name: 'NextDrawInfo',
  components: { FlagCategoryTournament, SidebarTitle },
  computed: {
    ...mapGetters([
      'retailNextEvent',
      'translate',
      'screenSize',
    ]),
    getFlagSize() {
      return {
        width: (this.screenSize === 1920 ? '45px' : '30px'),
        height: (this.screenSize === 1920 ? '30px' : '20px'),
      };
    },
    getMarkets() {
      const market = searchMarket(this.retailNextEvent.markets, '832');
      return chunk(market.outcomes, 4);
    },
  },
  methods: {
    getOdd(odd) {
      return getOdd(odd);
    },
  },
};
</script>

<style lang="scss" scoped>
.next-draw-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .market-chunk {
    background: rgba(255, 255, 255, 0.52);
    border-radius: 8px;
    display: flex;
    padding: 8px;
    margin-top: 8px;
    @media (min-width: 1920px) {
      margin-top: 16px;
      padding: 8px;
    }
    .market-outcome {
      align-items: center;
      color: #363636;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      opacity: .88;
      width: calc(100% / 4);
      @media (min-width: 1920px) {
        font-size: 18px;
        > div {
          line-height: 1.8;
        }
      }
      .outcome-value {
        font-weight: 400;
      }
      .outcome-odd {
        font-weight: 700;
      }
    }
  }
}
</style>
